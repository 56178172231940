import React from 'react';
import './index.css';
import { Link } from 'gatsby';
import PilotPlanner from '../../images/imgs/pilotPlanner.png';
// import Fask from '../../images/imgs/fask.png';
import PpDashborad from '../../images/imgs/ppDashboard.png';
import Invoices from '../../images/imgs/invoices.jpg';
// import Povio from '../../images/partners/povio.png';
// import FaskLogo from '../../images/partners/fask.png';
// import Oda from '../../images/partners/oda.png';
// import HeliCentre from '../../images/partners/helicentre.png';
import Gigster from '../../images/partners/gigster.png';
// import Fidola from '../../images/partners/fidola.svg';

const WorksPage = () => {
  return (
    <div className="main-container">
      <div className="container">
        <div className="container-1">
          <p className="container-1-text">
            The work we do,
            <br />
            and the people we help.
          </p>

          <div className="img-wrapper">
            <div className="container-img">
              <img src={PilotPlanner} alt="img-1" className="img-1" />
              <div className="container-text">
                <h6 className="container-text-1">Enhance your pilot training with comprehensive aviation education.</h6>
                <h6 className="container-text-2">Pilot Planner</h6>
              </div>
            </div>

            {/* <div className="container-img">
              <img src={Fask} alt="img-2" className="img-2" />
              <div className="container-text">
                <h6 className="container-text-1">Autosports license management & compliance and analytics reporting.</h6>
                <h6 className="container-text-2">Fask</h6>
              </div>
            </div> */}
          </div>
        </div>

        <div className="container-2">
          <div className="container-img">
            <img src={Invoices} alt="img-3" className="img-3" />
            <div className="container-text">
              <h6 className="container-text-1">Create, manage and send professional invoices with ease and efficiency</h6>
              <h6 className="container-text-2">Invoice Maker</h6>
            </div>
          </div>
        </div>

        <div className="container-3">
          <div className="container-3-img">
            <img src={PpDashborad} alt="img-3" className="img-3" />
            <div className="container-3-text">
              <div>
                <h6 className="container-text-1">The Pilot Planner dashboard offers lesson & question management and student tracking.</h6>
                <h6 className="container-text-2">Pilot Planner Dashboard</h6>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div>
        <div>
          <p className="partners-title">Partners</p>
        </div>

        <div className="partners-icons">
          {/* <img src={Povio} alt="povioImg"></img> */}
          {/* <img src={FaskLogo} alt="faskImg"></img> */}
          {/* <img style={{ background: '#000' }} src={HeliCentre} alt="helicenterImg"></img> */}
          <img src={Gigster} alt="gigsterImg"></img>
          {/* <img src={Oda} className="oda" alt="odaImg"></img> */}
          {/* <img src={Fidola} alt="fidolaImg"></img> */}
        </div>
      </div>

      <div className="container-4">
        <div>
          <p className="container-4-desc">
            Have a project in mind? <br />
            Let’s get to work.
          </p>
          <Link className="container-4-button" to="/hire-a-dev">
            Start a project
          </Link>
        </div>
      </div>
    </div>
  );
};

export default WorksPage;
